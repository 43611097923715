import { storeConstants } from "../../constants";

const defaultState = {
  items: [],
  loadingItems: false,
  fetchedItems: false,
  error: null
};

function normalData(arr) {
  if (!Array.isArray(arr)) return []

  return arr.map((elem, index) => Object.assign(elem, {
    id: elem.id,
    index: (index + 1),
    article: elem.nomenclature.article,
    serialNumber: elem.serialNumber,
    items: elem.equipments,
    nomenclatureId: elem.nomenclature.id,
    nomenclature: elem.nomenclature.nameNomenclature,
    isManufactured: elem.nomenclature.isManufactured,
    productId: elem.isProduct ? elem.id : null,
    category: elem.nomenclature.category ? elem.nomenclature.category.nameCategory : "Не указано",
    unit: elem.nomenclature.unit ? elem.nomenclature.unit.nameUnit : "Не указано",
    storage: elem.storage ? elem.storage.nameStorage : "Не указано",
    storageId: elem.storage ? elem.storage.id : null,
    storageIsArchive: elem.storage ? elem.storage.isArchive : null,
    count: elem.countAvailable,
    hasFractional: elem.nomenclature.hasFractional,
    isProduct: elem.isProduct,
  }))
}
function normalProductData(arr) {
  if (!Array.isArray(arr)) return []

  return arr.map((elem, index) => Object.assign(elem, {
    id: elem.id,
    index: (index + 1),
    article: elem.nomenclature.article,
    serialNumber: elem.serialNumber,
    items: elem.equipments,
    nomenclatureId: elem.nomenclature.id,
    nomenclature: elem.nomenclature.nameNomenclature,
    isManufactured: elem.nomenclature.isManufactured,
    productId: elem.isProduct ? elem.id : null,
    category: elem.nomenclature.category ? elem.nomenclature.category.nameCategory : "Не указано",
    unit: elem.nomenclature.unit ? elem.nomenclature.unit.nameUnit : "Не указано",
    storage: elem.storage ? elem.storage.nameStorage : "Не указано",
    storageId: elem.storage ? elem.storage.id : null,
    storageIsArchive: elem.storage ? elem.storage.isArchive : null,
    count: elem.countAvailable,
    hasFractional: elem.nomenclature.hasFractional,
    isProduct: elem.isProduct,
    dateStart: elem.product.dateStart,
    dateFinish: elem.product.dateFinish,
    manufactureStatus: elem.manufactureStatus,
    specificationId: elem.product?.specification?.id,
    specificationName: elem.product?.specification?.name,
  }))
}

export const storeReducer = function (state = defaultState, action) {
  switch (action.type) {
    case storeConstants.GETALL_REQUEST_STORES:
      return {
        ...state,
        loadingItems: true,
        fetchedItems: false,
        error: null
      };
    case storeConstants.GETALL_SUCCESS_STORES:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case storeConstants.GETALL_FAILURE_STORES:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case "RESET_ITEMS_STORES":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null
      }
    default:
      return state;
  }
}