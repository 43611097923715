import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Аналитика",
    path: "/dashboard",
    icon: "dashboard",
    noChildren: true,
  },
  {
    name: "Показатели",
    icon: "assessment",
    children: [
      {
        name: "Поставки",
        path: "/store/invoices",
        icon: "assignment_returned",
      },
      {
        name: "Отгрузки",
        path: "/store/reserved",
        icon: "assignment_turned_in",
      },
    ]
  },
  {
    name: "Продажи",
    icon: "shop_two",
    children: [
      {
        name: "Заказ клиента",
        iconText: "ЗК",
        path: "/documents/order",
        auth: authRoles.user,
      },
      {
        name: "Отгрузка",
        iconText: "ОТ",
        path: "/documents/shipment",
        auth: authRoles.user,
      },
    ],
  },
  {
    name: "Закупки",
    icon: "library_books",
    auth: authRoles.user,
    children: [
      {
        name: "Заказ поставщику",
        iconText: "ЗП",
        path: "/documents/invoice",
        auth: authRoles.user,
      },
      {
        name: "Приемка",
        iconText: "ПР",
        path: "/documents/coming",
        auth: authRoles.user,
      },
    ],
  },
  {
    name: "Склад",
    icon: "library_add",
    auth: authRoles.user,
    children: [
      {
        name: "Остатки",
        path: "/store/stores",
        icon: "store",
      },
      {
        name: "Оприходование",
        iconText: "ОП",
        path: "/documents/recording",
        auth: authRoles.user,
      },
      {
        name: "Списание",
        iconText: "СП",
        path: "/documents/writedown",
        auth: authRoles.user,
      },
      {
        name: "Перемещение",
        iconText: "ПМ",
        path: "/documents/move",
        auth: authRoles.user,
      }
    ],
  },
  {
    name: "Производство",
    icon: "factory",
    children: [
      {
        name: "Изделия",
        iconText: "И",
        path: "/manufacture/products",
        auth: authRoles.user,
      },
      {
        name: "Заявки",
        iconText: "З",
        path: "/manufacture/applications",
        auth: authRoles.user,
      },
      {
        name: "Комплектация",
        iconText: "К",
        path: "/manufacture/equipment",
        auth: authRoles.user,
      },
    ],
  },
  {
    name: "Справочники",
    icon: "auto_stories",
    auth: authRoles.user,
    children: [
      {
        name: "Номенклатура",
        iconText: "Н",
        auth: authRoles.user,
        path: "/nomenclatures/list",
        // children: [
        //   {
        //     name: "Список",
        //     iconText: "Т",
        //     path: "/nomenclatures/list",
        //     auth: authRoles.user,
        //   },
        //   {
        //     name: "Категории",
        //     iconText: "К",
        //     path: "/nomenclatures/categories",
        //     auth: authRoles.user,
        //   },
        // ],
      },
      {
        name: "Контрагенты",
        iconText: "recent_actors",
        auth: authRoles.user,
        path: "/contractors/list",
        // children: [
        //   {
        //     name: "Список контрагентов",
        //     iconText: "К",
        //     path: "/contractors/list",
        //     auth: authRoles.user,
        //   },
        //   {
        //     name: "Группы",
        //     iconText: "Г",
        //     path: "/contractors/groups",
        //     auth: authRoles.user,
        //   },
        // ],
      },
      {
        name: "Договоры",
        iconText: "insert_drive_file",
        auth: authRoles.user,
        path: "/contracts/list",
        // children: [
        //   {
        //     name: "Список договоров",
        //     iconText: "Д",
        //     path: "/contracts/list",
        //     auth: authRoles.user,
        //   },
        //   {
        //     name: "Статусы",
        //     iconText: "С",
        //     path: "/contracts/statuses",
        //     auth: authRoles.user,
        //   },
        //   {
        //     name: "Типы",
        //     iconText: "Т",
        //     path: "/contracts/types",
        //     auth: authRoles.user,
        //   },
        // ],
      },
      {
        name: "Общие",
        iconText: "book",
        auth: authRoles.user,
        children: [
          {
            name: "Организации",
            iconText: "О",
            path: "/guides/organizations",
            auth: authRoles.user,
          },
          {
            name: "Склады",
            iconText: "С",
            path: "/guides/storages",
            auth: authRoles.user,
          },
          {
            name: "Типы цен",
            iconText: "Ц",
            path: "/guides/prices",
            auth: authRoles.user,
          },
          {
            name: "Страны",
            iconText: "С",
            path: "/guides/countries",
            auth: authRoles.user,
          },
          {
            name: "Единицы измерения",
            iconText: "Е",
            path: "/guides/units",
            auth: authRoles.user,
          },
          {
            name: "Налоги",
            iconText: "Н",
            path: "/guides/taxes",
            auth: authRoles.user,
          },
          {
            name: "Валюты",
            iconText: "В",
            path: "/guides/currencies",
            auth: authRoles.user,
          },
          {
            name: "Автонумерация",
            iconText: "А",
            path: "/guides/autonumbers",
            auth: authRoles.user,
          },
        ],
      },
    ]
  },
  {
    name: "Администрирование",
    icon: "admin_panel_settings",
    auth: authRoles.admin,
    children: [
      {
        name: "Пользователи",
        path: "/users/list",
        icon: "people",
        auth: authRoles.admin,
      },
      {
        name: "Отделы",
        path: "/departments",
        icon: "tune",
        auth: authRoles.admin,
      },
    ]
  },

];
