import axios from "../../axios";
import { getType } from "../views/guides/Modals";

export const documentService = {
  getAllDocuments,

  getAllRecordings,
  getRecordingById,
  createRecording,
  updateRecording,

  getAllWriteDowns,
  getWriteDownById,
  createWriteDown,
  updateWriteDown,

  getAllMoves,
  getMoveById,
  createMove,
  updateMove,

  getAllInvoices,
  getInvoiceById,
  createInvoice,
  updateInvoice,
  changeStatusInvoice,

  getAllComings,
  getComingById,
  createComing,
  updateComing,
  getUndeliveredItemsById,

  getAllOrders,
  getOrderById,
  createOrder,
  updateOrder,
  changePay,

  getAllShipments,
  getShipmentById,
  createShipment,
  updateShipment,
  getUnsentItemsById,

  archiveDocument,
  deleteDocument,
};

//#region Все документы
async function getAllDocuments(params) {
  const response = await axios.get(
    `Documents${params?.type ? `?type=${params.type}` : ""}${
      params?.isArchive ? `?isArchive=${params.isArchive}` : ""
    }`
  );
  return response.data;
}
//#endregion

//#region Поместить в архив
async function archiveDocument(id, type) {
  
  const response = await axios.patch(`Documents/ArchiveDocument/${id}?type=${getType(type)}`);
  return response.data;
}
//#endregion

//#region Удалить документ
async function deleteDocument(id, type){
  const response = await axios.patch(`Documents/DeleteDocument/${id}?type=${getType(type)}`);
  return response.data;
}

//#region Оприходование
async function getAllRecordings(params) {
  const response = await axios.get(
    `Documents/Recording${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getRecordingById(id) {
  const response = await axios.get(`Documents/Recording/${id}`);
  return response.data;
}

async function createRecording(params) {
  const response = await axios.post(`Documents/Recording`, params);
  return response.data;
}

async function updateRecording(id, params) {
  const response = await axios.put(`Documents/Recording/Update/${id}`, params);
  return response.data;
}
//#endregion

//#region Списание
async function getAllWriteDowns(params) {
  const response = await axios.get(
    `Documents/WriteDown${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getWriteDownById(id) {
  const response = await axios.get(`Documents/WriteDown/${id}`);
  return response.data;
}

async function createWriteDown(params) {
  const response = await axios.post(`Documents/WriteDown`, params);
  return response.data;
}

async function updateWriteDown(id, params) {
  const response = await axios.put(`Documents/WriteDown/Update/${id}`, params);
  return response.data;
}
//#endregion

//#region Перемещение
async function getAllMoves(params) {
  const response = await axios.get(
    `Documents/Move${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getMoveById(id) {
  const response = await axios.get(`Documents/Move/${id}`);
  return response.data;
}

async function createMove(params) {
  const response = await axios.post(`Documents/Move`, params);
  return response.data;
}

async function updateMove(id, params) {
  const response = await axios.put(`Documents/Move/${id}`, params);
  return response.data;
}
//#endregion

//#region Заказ поставщику
async function getAllInvoices(params) {
  const response = await axios.get(
    `Documents/Invoice${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getInvoiceById(id) {
  const response = await axios.get(`Documents/Invoice/${id}`);
  return response.data;
}

async function createInvoice(params) {
  const response = await axios.post(`Documents/Invoice`, params);
  return response.data;
}

async function updateInvoice(id, params) {
  const response = await axios.put(`Documents/Invoice/Update/${id}`, params);
  return response.data;
}

async function changeStatusInvoice(id) {
  const response = await axios.put(`Documents/Invoice/ChangeStatus/${id}`);
  return response.data;
}
//#endregion

//#region Приемка
async function getAllComings(params) {
  const response = await axios.get(
    `Documents/Coming${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getComingById(id) {
  const response = await axios.get(`Documents/Coming/${id}`);
  return response.data;
}

async function createComing(params) {
  const response = await axios.post(`Documents/Coming`, params);
  return response.data;
}

async function updateComing(id, params) {
  const response = await axios.put(`Documents/Coming/Update/${id}`, params);
  return response.data;
}

async function getUndeliveredItemsById(id) {
  const response = await axios.get(`Documents/Invoice/UndeliveredItems/${id}`);
  return response.data;
}
//#endregion

//#region Заказ клиента
async function getAllOrders(params) {
  const response = await axios.get(
    `Documents/Order${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getOrderById(id, params) {
  const queryParams = params ? `?isDupl=${params}` : "";
  const response = await axios.get(`Documents/Order/${id}${queryParams}`);
  return response.data;
}

async function createOrder(params) {
  const response = await axios.post(`Documents/Order`, params);
  return response.data;
}

async function updateOrder(id, params) {
  const response = await axios.put(`Documents/Order/Update/${id}`, params);
  return response.data;
}

async function changePay(id, params) {
  const response = await axios.put(`Documents/Order/ChangeStatus/${id}`,params);
  return response.data;
}
//#endregion

//#region Отгрузка
async function getAllShipments(params) {
  const response = await axios.get(
    `Documents/Shipment${params?.isArchive ? `?isArchive=${params.isArchive}` : ""}`
  );
  return response.data;
}

async function getShipmentById(id) {
  const response = await axios.get(`Documents/Shipment/${id}`);
  return response.data;
}

async function createShipment(params) {
  const response = await axios.post(`Documents/Shipment`, params);
  return response.data;
}

async function updateShipment(id, params) {
  const response = await axios.put(`Documents/Shipment/Update/${id}`, params);
  return response.data;
}

async function getUnsentItemsById(id) {
  const response = await axios.get(`Documents/Order/UnsentItems/${id}`);
  return response.data;
}
//#endregion
