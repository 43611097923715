import { combineReducers } from "redux";

import { reducer as notificationsReducer } from "reapop";
import { setUpNotifications } from "reapop";

import NavigationReducer from "./NavigationReducer";

import UserReducer from "./users/UserReducer";
import UserGroupReducer from "./users/UserGroupReducer";
import EmailUserReducer from "./users/EmailUserReducer";

import { organizationReducer, countryReducer, unitReducer, taxReducer, currencyReducer, storageReducer, priceReducer, numberReducer } from "./guides";
import { groupReducer, contractorReducer } from "./contractors";
import { categoryReducer, nomenclatureReducer } from "./nomenclatures";
import { contractReducer, statusReducer, typeReducer } from "./contracts";
import { storeReducer, invoiceStoreReducer, reserveReducer } from "./stores";
import { recordingReducer, writeDownReducer, moveReducer, invoiceReducer, comingReducer, orderReducer, shipmentReducer } from "./documents";
import { printReducer } from "./prints/PrintReducer";

import AppReducer from "./AppReducer";
import {equipmentReducer, manufactureReducer} from "./manufacture";
import { productReducer } from "./stores/ProductReducer";

// атрибуты уведомлений по умолчанию(метод из reapop)
setUpNotifications({
  defaultProps: {
    position: "bottom-right",
    dismissible: true,
    dismissAfter: 5000,
    showDismissButton: true,
  },
});

const storesReducer = combineReducers({
  stores: storeReducer,
  invoices: invoiceStoreReducer,
  reserved: reserveReducer,
  products: productReducer,
});

const guidesReducer = combineReducers({
  organizations: organizationReducer,
  countries: countryReducer,
  units: unitReducer,
  taxes: taxReducer,
  currencies: currencyReducer,
  storages: storageReducer,
  prices: priceReducer,
  numbers: numberReducer,
});

const contractorsReducer = combineReducers({
  contractors: contractorReducer,
  groups: groupReducer,
});

const nomenclaturesReducer = combineReducers({
  nomenclatures: nomenclatureReducer,
  categories: categoryReducer,
});

const contractsReducer = combineReducers({
  contracts: contractReducer,
  statuses: statusReducer,
  types: typeReducer,
});

const documentsReducer = combineReducers({
  recording: recordingReducer,
  writedown: writeDownReducer,
  move: moveReducer,
  invoice: invoiceReducer,
  coming: comingReducer,
  order: orderReducer,
  shipment: shipmentReducer,
});

const usersReducer = combineReducers({
  usersList: UserReducer,
  usersGroup: UserGroupReducer,
  userEmailList: EmailUserReducer
});

const manufacturersReducer = combineReducers({
  equipment: equipmentReducer,
  manufacture: manufactureReducer
});

const RootReducer = combineReducers({
  notifications: notificationsReducer(),
  navigations: NavigationReducer,
  app: AppReducer,
  users: usersReducer,
  guides: guidesReducer,
  contractors: contractorsReducer,
  nomenclatures: nomenclaturesReducer,
  contracts: contractsReducer,
  stores: storesReducer,
  documents: documentsReducer,
  manufacturers: manufacturersReducer,
  prints: printReducer
});

export default RootReducer;
