export const storeConstants = {
    GETALL_REQUEST_STORES: "GETALL_REQUEST_STORES",
    GETALL_SUCCESS_STORES: "GETALL_SUCCESS_STORES",
    GETALL_FAILURE_STORES: "GETALL_FAILURE_STORES",

    GETBYID_REQUEST_STORES: "GETBYID_REQUEST_STORES",
    GETBYID_SUCCESS_STORES: "GETBYID_SUCCESS_STORES",
    GETBYID_FAILURE_STORES: "GETBYID_FAILURE_STORES",

    UPDATE_REQUEST_STORES: "UPDATE_REQUEST_STORES",
    UPDATE_SUCCESS_STORES: "UPDATE_SUCCESS_STORES",
    UPDATE_FAILURE_STORES: "UPDATE_FAILURE_STORES",

    CREATE_REQUEST_STORES: "CREATE_REQUEST_STORES",
    CREATE_SUCCESS_STORES: "CREATE_SUCCESS_STORES",
    CREATE_FAILURE_STORES: "CREATE_FAILURE_STORES",

    ARCHIVE_REQUEST_STORES: "ARCHIVE_REQUEST_STORES",
    ARCHIVE_SUCCESS_STORES: "ARCHIVE_SUCCESS_STORES",
    ARCHIVE_FAILURE_STORES: "ARCHIVE_FAILURE_STORES",

    DELETE_REQUEST_STORES: "DELETE_REQUEST_STORES",
    DELETE_SUCCESS_STORES: "DELETE_SUCCESS_STORES",
    DELETE_FAILURE_STORES: "DELETE_FAILURE_STORES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_PRODUCTS: "GETALL_REQUEST_PRODUCTS",
    GETALL_SUCCESS_PRODUCTS: "GETALL_SUCCESS_PRODUCTS",
    GETALL_FAILURE_PRODUCTS: "GETALL_FAILURE_PRODUCTS",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_INVOICES: "GETALL_REQUEST_INVOICES",
    GETALL_SUCCESS_INVOICES: "GETALL_SUCCESS_INVOICES",
    GETALL_FAILURE_INVOICES: "GETALL_FAILURE_INVOICES",

    GETBYID_REQUEST_INVOICES: "GETBYID_REQUEST_INVOICES",
    GETBYID_SUCCESS_INVOICES: "GETBYID_SUCCESS_INVOICES",
    GETBYID_FAILURE_INVOICES: "GETBYID_FAILURE_INVOICES",

    UPDATE_REQUEST_INVOICES: "UPDATE_REQUEST_INVOICES",
    UPDATE_SUCCESS_INVOICES: "UPDATE_SUCCESS_INVOICES",
    UPDATE_FAILURE_INVOICES: "UPDATE_FAILURE_INVOICES",

    CREATE_REQUEST_INVOICES: "CREATE_REQUEST_INVOICES",
    CREATE_SUCCESS_INVOICES: "CREATE_SUCCESS_INVOICES",
    CREATE_FAILURE_INVOICES: "CREATE_FAILURE_INVOICES",

    ARCHIVE_REQUEST_INVOICES: "ARCHIVE_REQUEST_INVOICES",
    ARCHIVE_SUCCESS_INVOICES: "ARCHIVE_SUCCESS_INVOICES",
    ARCHIVE_FAILURE_INVOICES: "ARCHIVE_FAILURE_INVOICES",

    DELETE_REQUEST_INVOICES: "DELETE_REQUEST_INVOICES",
    DELETE_SUCCESS_INVOICES: "DELETE_SUCCESS_INVOICES",
    DELETE_FAILURE_INVOICES: "DELETE_FAILURE_INVOICES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_RESERVED: "GETALL_REQUEST_RESERVED",
    GETALL_SUCCESS_RESERVED: "GETALL_SUCCESS_RESERVED",
    GETALL_FAILURE_RESERVED: "GETALL_FAILURE_RESERVED",

    GETBYID_REQUEST_RESERVED: "GETBYID_REQUEST_RESERVED",
    GETBYID_SUCCESS_RESERVED: "GETBYID_SUCCESS_RESERVED",
    GETBYID_FAILURE_RESERVED: "GETBYID_FAILURE_RESERVED",

    UPDATE_REQUEST_RESERVED: "UPDATE_REQUEST_RESERVED",
    UPDATE_SUCCESS_RESERVED: "UPDATE_SUCCESS_RESERVED",
    UPDATE_FAILURE_RESERVED: "UPDATE_FAILURE_RESERVED",

    CREATE_REQUEST_RESERVED: "CREATE_REQUEST_RESERVED",
    CREATE_SUCCESS_RESERVED: "CREATE_SUCCESS_RESERVED",
    CREATE_FAILURE_RESERVED: "CREATE_FAILURE_RESERVED",

    ARCHIVE_REQUEST_RESERVED: "ARCHIVE_REQUEST_RESERVED",
    ARCHIVE_SUCCESS_RESERVED: "ARCHIVE_SUCCESS_RESERVED",
    ARCHIVE_FAILURE_RESERVED: "ARCHIVE_FAILURE_RESERVED",

    DELETE_REQUEST_RESERVED: "DELETE_REQUEST_RESERVED",
    DELETE_SUCCESS_RESERVED: "DELETE_SUCCESS_RESERVED",
    DELETE_FAILURE_RESERVED: "DELETE_FAILURE_RESERVED",
};
